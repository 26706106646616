import React from 'react';
import './../../Styles/fonts.css';
import './../../Styles/footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer-content">
        {/* <div className="footer-section">
          <h2>من نحن</h2>
          <p>Establishing Local Presence and Setting off to the International Markets</p>
        </div> */}
        <div className="footer-section">
          <h2>Contact Us</h2>
          <p>Phone: 009647800099988</p>
          <p>Email: info@brk.iq</p>
        </div>
        <div className="footer-section">
        <h2>Follow Us</h2>
          <a
          style={{marginRight: '10px'}}
            href="https://www.instagram.com/brk.iq/profilecard/?igsh=MXY2Mmd3OGF4N3NyeQ=="
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-icon"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a
            href="https://www.facebook.com/share/41ASKExmeCUQyzsE/?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-icon"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>BRK Al-Baraka Company {currentYear} &copy; All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
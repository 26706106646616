import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import image from './../../images/3.1.jpg';
import image_1 from './../../images/1.jpg';
import image_32 from './../../images/32.jpg';
import factory_2 from './../../images/Factory2.jpg';
import Untitled_2_02 from './../../images/Untitled-2-02.png';
// import aboutus from './../../images/aboutus.jpg';
import image1 from './../../images/Sanitation.jpg';
import image2 from './../../images/PPR.jpg';
import image3 from './../../images/PRO.jpg';
import image4 from './../../images/ELE.jpg';
import image5 from './../../images/PP,PE,ETC BANNER.jpg';
import partner1 from './../../images/partners/Anton oil.png';
import partner2 from './../../images/partners/BOC.jpg';
import partner3 from './../../images/partners/CNPC.png';
import partner6 from './../../images/partners/AUIB.jpg';
import partner10 from './../../images/partners/ROO.png';
import partner11 from './../../images/partners/SGC.jpg';
import partner12 from './../../images/partners/Shell.png';
import partner13 from './../../images/partners/SLB.png';
import partner16 from './../../images/partners/الزهراء السكني - بغداد.jpg';
import partner19 from './../../images/partners/العطيفيه السكني.jpg';
import partner20 from './../../images/partners/اللؤلؤه السكني.jpg';
import partner21 from './../../images/partners/النسيم السكني.jpg';
import partner22 from './../../images/partners/اليرموك السكني.jpg';
import partner23 from './../../images/partners/امانة بغداد.jpg';
import partner25 from './../../images/partners/جوهرة البصره.jpg';
import partner26 from './../../images/partners/جوهرت الفرات.jpg';
import partner27 from './../../images/partners/دار السلام السكني.jpg';
import partner28 from './../../images/partners/درة البصره السكني.jpg';
import partner30 from './../../images/partners/شركة الانابيب النفطية.jpg';
import partner31 from './../../images/partners/شركة المشاريع النفطية.jpg';
import partner32 from './../../images/partners/شركة غاز البصره.png';
import partner33 from './../../images/partners/عين البصره السكني الامل السكني.jpg';
import partner34 from './../../images/partners/مجمع شناشيل بغداد.jpg';
import partner35 from './../../images/partners/مكي السكني.jpg';
import partner36 from './../../images/partners/وزارة الاعمار والاسكان والبلديات العامة.jpg';
import partner38 from './../../images/partners/وزارة الصناعة والمعادن.jpg';
import partner40 from './../../images/partners/وزارة النفط العراقية.png';
import './../../Styles/fonts.css';
import WhatsAppIcon from './../utils/WhatsAppIcon';
import Footer from './../utils/footer';
import './../../Styles/Widget.css';


const Home = () => {
  const servicesRef = useRef(null);
  const location = useLocation();
  const [heroIndex, setHeroIndex] = useState(0); // For hero images
  const [partnerIndex, setPartnerIndex] = useState(0); // For partners
  const [itemsPerView, setItemsPerView] = useState(1000);
  const partners = [
    partner1, partner2, partner3, partner6,
    partner10, partner11, partner12, partner13, partner16,
    partner19, partner20, partner21, partner22, partner23, partner25,
    partner26, partner27, partner28, partner30, partner31, partner32, partner33,
    partner34, partner35, partner36, partner38, partner40
  ];

  const heroImages = [
    Untitled_2_02, image, image_1, image_32, factory_2 // List all your hero images here
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setHeroIndex(prevIndex => (prevIndex + 1) % heroImages.length);
    }, 2000); // Change image every 2 seconds

    return () => clearInterval(interval); // Cleanup the interval
  }, [heroImages.length]);

  const nextPartner = () => {
    if (partnerIndex + itemsPerView < partners.length) {
      setPartnerIndex((prevIndex) => prevIndex + 1);
    }
  };
  
  const prevPartner = () => {
    if (partnerIndex > 0) {
      setPartnerIndex((prevIndex) => prevIndex - 1);
    }
  };
  

  useEffect(() => {
    const updateItemsPerView = () => {
      setItemsPerView(window.innerWidth <= 768 ? 1 : 4);
    };

    window.addEventListener('resize', updateItemsPerView);
    updateItemsPerView(); // Initialize on load

    return () => {
      window.removeEventListener('resize', updateItemsPerView);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === '/services') {
      servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div>
      {/* Hero Image */}
      <div className="image-container">
        <img src={heroImages[heroIndex]} alt="Description" />
        {/* <div className="text-overlay">
          <h5>YOUR</h5>
          <h5>SUPPORTIVE</h5>
          <h5>PARTNER</h5>
        </div> */}
      </div>
      

      {/* Widgets Container */}
      <div
        className="widgets-container"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '20px',
          marginTop: '100px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            marginBottom: '60px',
            marginLeft: '50px',
            flexWrap: 'wrap',
          }}
        >
          {/* Image Section */}
          {/* <div
            className="about-us-image"
            style={{
              maxWidth: '30%',
              textAlign: 'center',
              marginRight: '50px',
              marginBottom: '20px',
            }}
          >
            <img
              src={aboutus} // Larger Image for the right side
              alt="Description"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </div> */}

          {/* Text Section */}
          {/* <div
            className="text-section"
            style={{
              maxWidth: '100%',
              width: '50%',
              textAlign: 'justify',
              textRendering: 'optimizeLegibility',
              marginBottom: '20px',
            }}
          >
            <h2 style={{ borderBottom: '4px solid #faba20', paddingBottom: '10px' }}>About Us</h2>
            <p className="mobile-text-style">
            Albaraka For Industrial Investments was initiated in 1985, hands on producing plastic products. Albaraka specialized in manufacturing of plastic pipes and fittings for both sewage and water foundation networks since 1995, and according to the local market’s need  ,Despite the challenges Albaraka faced starting from the scarcity of raw materials and the lack of exposure opportunities, Albaraka worked to overcome obstacles, as the founders assembled the first machine using the avalable materials at the time. The hard work resulted in the first production line of plastic pipes
with a quality that matched  the qualitiy of global products and a price that suited the local purchasing power at the time
            </p>
            <button
              style={{
                backgroundColor: '#faba20',
                border: 'none',
                padding: '12px 30px',
                fontSize: '16px',
                fontWeight: 'bold',
                cursor: 'pointer',
                borderRadius: '5px',
                color: '#fff',
              }}
            >
              Learn More
            </button>
          </div> */}
        </div>

        {/* Light line between sections */}
        {/* <div
          style={{
            borderTop: '2px solid #dcdcdc',
            width: '100%',
            marginTop: '50px',
            marginBottom: '50px',
          }}
        ></div> */}

        {/* Products Section */}
        <h2 style={{ borderBottom: '4px solid #faba20' }}>Products</h2>

        {/* 4 Images Section */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '100px',
            flexWrap: 'wrap',
            gap: '20px',
          }}
        >
          <div style={{ textAlign: 'center', flex: '1 1 30%' }}>
            <img
              src={image1}
              alt="SANITATION"
              style={{
                width: '100%',
                height: '200px',
                objectFit: 'cover',
                marginBottom: '-20px',
                clipPath: 'polygon(0 0, 70% 0, 100% 30%, 100% 100%, 0% 100%)',
              }}
            />
            <h4 style={{ borderBottom: '2px solid #faba20' }}>PVC ORG</h4>
          </div>

          <div style={{ textAlign: 'center', flex: '1 1 30%' }}>
            <img
              src={image2}
              alt="PPR"
              style={{
                width: '100%',
                height: '200px',
                objectFit: 'cover',
                marginBottom: '-20px',
                clipPath: 'polygon(0 0, 70% 0, 100% 30%, 100% 100%, 0% 100%)',
              }}
            />
            <h4 style={{ borderBottom: '2px solid #faba20' }}>PPR</h4>
          </div>

          <div style={{ textAlign: 'center', flex: '1 1 30%' }}>
            <img
              src={image3}
              alt="PRO"
              style={{
                width: '100%',
                height: '200px',
                objectFit: 'cover',
                marginBottom: '-20px',
                clipPath: 'polygon(0 0, 70% 0, 100% 30%, 100% 100%, 0% 100%)',
              }}
            />
            <h4 style={{ borderBottom: '2px solid #faba20' }}>PVC PRO</h4>
          </div>

          <div style={{ textAlign: 'center', flex: '1 1 30%' }}>
            <img
              src={image4}
              alt="UPVC"
              style={{
                width: '100%',
                height: '200px',
                objectFit: 'cover',
                marginBottom: '-20px',
                clipPath: 'polygon(0 0, 70% 0, 100% 30%, 100% 100%, 0% 100%)',
              }}
            />
            <h4 style={{ borderBottom: '2px solid #faba20' }}>ELE</h4>
          </div>

          <div style={{ textAlign: 'center', flex: '1 1 30%' }}>
            <img
              src={image5}
              alt="UPVC"
              style={{
                width: '100%',
                height: '200px',
                objectFit: 'cover',
                marginBottom: '-20px',
                clipPath: 'polygon(0 0, 70% 0, 100% 30%, 100% 100%, 0% 100%)',
              }}
            />
            <h4 style={{ borderBottom: '2px solid #faba20' }}>PE, PP, ETC</h4>
          </div>

          <div style={{ textAlign: 'center', flex: '1 1 30%' }}></div>
        </div>


        <button
          style={{
            backgroundColor: '#faba20',
            border: 'none',
            padding: '12px 30px',
            fontSize: '16px',
            fontWeight: 'bold',
            cursor: 'pointer',
            borderRadius: '5px',
            color: '#fff',
          }}
        >
          Learn More
        </button>

        {/* Light line between sections */}
        <div
          style={{
            borderTop: '2px solid #dcdcdc',
            width: '100%',
            marginTop: '50px',
            marginBottom: '50px',
          }}
        ></div>

        {/* partners Widget Section */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "100px",
          }}
        >
          <div style={{ maxWidth: "100%", textAlign: "center" }}>
            {/* Section Header */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "50px",
              }}
            >
              <div
                style={{
                  width: "5px",
                  height: "30px",
                  backgroundColor: "#faba20",
                  marginRight: "15px",
                }}
              ></div>
              <h2 style={{ margin: 0 }}>Partners</h2>
            </div>

            {/* Carousel */}
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                width: "100%",
              }}
            >
              {/* Previous Button */}
              <button
                onClick={prevPartner}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "30px",
                  cursor: "pointer",
                  padding: "10px",
                  color: "#faba20",
                }}
                aria-label="Previous Partner"
              >
                &#8249;
              </button>

              {/* Partner Images */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap", // Wrap rows on smaller screens
                  alignItems: "center",
                  gap: "20px", // Space between images
                }}
              >
                {partners.slice(partnerIndex, partnerIndex + itemsPerView).map((partner, index) => (
                  <div
                    key={index}
                    style={{
                      flex: "1 1 23%",
                      maxWidth: "200px",
                      height: "200px",
                      margin: "0 auto",
                      overflow: "hidden",
                      borderRadius: "5px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <img
                      src={partner}
                      alt={`Partner ${index + 1}`} // Add alt text for better accessibility
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        backgroundColor: "#f8f8f8",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                ))}
                {/* Next Button */}
                <button
                  onClick={nextPartner}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "30px",
                    cursor: "pointer",
                    padding: "10px",
                    color: "#faba20",
                  }}
                  aria-label="Next Partner"
                >
                  &#8250;
                </button>
              </div>


            </div>
          </div>
        </div>

        {/* Light line between sections */}
        <div
          style={{
            borderTop: '2px solid #dcdcdc',
            width: '100%',
            marginTop: '50px',
            marginBottom: '50px',
          }}
        ></div>
      </div>
      <WhatsAppIcon />
      <Footer />
    </div>
  );
};

export default Home;
import React from 'react';
import WhatsAppIconImage from './../../images/WhatsApp_icon.png';

const WhatsAppIcon = () => {
  return (
    <div style={whatsappIconStyle}>
      <a href="https://wa.me/9647800099988" target="_blank" rel="noopener noreferrer">
        <img src={WhatsAppIconImage} alt="WhatsApp" style={iconImageStyle} />
      </a>
    </div>
  );
};

const whatsappIconStyle = {
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  zIndex: '1000',
};

const iconImageStyle = {
  width: '60px',
  height: '60px',
  borderRadius: '50%',
};

export default WhatsAppIcon;

import React, { Component } from 'react';
import { withRouter } from '../utils/withRouter';
import { Link } from 'react-router-dom';
import image from './../../images/logo.png';
import './../../Styles/Navbar.css';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Navbar extends Component {
    state = {
        isOpened: false,
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ isOpened: false });
        }
    }

    handleToggleSidebar = () => {
        this.props.toggleSidebar();
        this.setState(prevState => ({
            isOpened: !prevState.isOpened
        }));
    }

    render() {
        const icon = this.state.isOpened ? '✕' : '☰';

        return (
            // <nav className="navbar" style={{ fontFamily: 'Droid Arabic Kufi' }}>
            <nav className="navbar">
                <div className="contact-section">
                    <div className="phone-container">
                        <div className="phone-icon-container">
                            <FontAwesomeIcon icon={faPhone} className="phone-icon" />
                        </div>
                        <span className="contact-text">+964(780)009-9988</span>
                    </div>
                </div>
                <div className="contact-section">
                    <div className="email-container">
                        <div className="email-icon-container">
                            <FontAwesomeIcon icon={faEnvelope} className="email-icon" />
                        </div>
                        <span className="contact-text">info@brk.iq</span>
                    </div>
                </div>


                <div className="burger-menu" onClick={this.handleToggleSidebar}>
                    {icon}
                </div>
                <ul className="nav-items">
                    <li><Link to="/" onClick={this.props.toggleSidebar}>Home</Link></li>
                    <li><Link to="/contact" onClick={this.props.toggleSidebar}>Contact Us</Link></li>
                    <li><Link to="/about" onClick={this.props.toggleSidebar}>Products</Link></li>
                    <li><Link to="/services" onClick={this.props.toggleSidebar}>Partners</Link></li>
                </ul>
                <div className="navbar-brand">
                    <img
                        style={{ width: '200px' }}
                        src={image}
                        alt="Description"
                        className="navbar-image"
                    />
                </div>

            </nav>
        );
    }
}

export default withRouter(Navbar);
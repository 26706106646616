import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/main/Navbar';
import Sidebar from './components/main/Sidebar';
import Home from './components/pages/Home';
// import About from './components/pages/About';
// import Contact from './components/pages/Contact';
import './App.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

class App extends Component {
  state = {
    isSidebarOpen: false,
    isMobileView: false,
  };

  componentDidMount() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScreenWidth);
  }

  checkScreenWidth = () => {
    const isMobile = window.innerWidth <= 768;
    this.setState({
      isMobileView: isMobile,
      isSidebarOpen: false,
    });
  };

  toggleSidebar = () => {
    if (this.state.isMobileView) {
      this.setState((prevState) => ({
        isSidebarOpen: !prevState.isSidebarOpen,
      }));
    }
  };

  render() {
    return (
      <Router>
        <div className="App">
          <Navbar toggleSidebar={this.toggleSidebar} />
          <Sidebar isOpen={this.state.isSidebarOpen} toggleSidebar={this.toggleSidebar} />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/about" element={<About />} /> */}
              {/* <Route path="/contact" element={<Contact />} /> */}
              <Route path="/services" element={<Home />} />
            </Routes>
          </main>
        </div>
      </Router>
    );
  }
}

export default App;